<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <table aria-describedby="">
          <tr>
            <td>
              <div class="search">
                <input type="text" class="form-control input-sm" placeholder="ค้นหาสินค้าโดยชื่อ..."
                  v-model="searchVal" />
                <button type="submit" class="btn btnsearch btn-sm text-right">
                  <CIcon name="cil-magnifying-glass"></CIcon>
                </button>
              </div>
            </td>
            <td style="width: 12%" class="text-grey">
              <small style="font-size: 14px">
                <span @click="handleDisplay('list')">
                  <CIcon name="cil-list"></CIcon>
                </span>
                <span @click="handleDisplay('grid')" style="float: right">
                  <CIcon name="cil-grid"></CIcon>
                </span>
              </small>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="products.length === 0" class="text-center mt-5">
        <img alt="" class="img-fluid" width="30%" src="https://cdn-icons-png.flaticon.com/512/776/776495.png"
          onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';" />
        <p class="mt-3 font-weight-bold">ไม่มีรายการสินค้า</p>
      </div>

      <div v-else class="row">
        <div v-if="display === 'list'" class="col">
          <table class="mb-5" aria-describedby="">
            <tr v-for="product in products" :key="product.objectId" style="border-bottom: 1px solid rgba(246, 246, 246)"
              @click="SKUObjectId = product.ProductSKU.objectId" data-toggle="modal" data-target="#detailModal">
              <td style="width: 25%; padding: 2px">
                <div v-if="product.remoteImagePath === null ||
                  product.remoteImagePath === undefined
                  " class="square-box shadow-menu" :style="{
    'background-color': getImgColor(product.indexColor),
    width: '100%',
  }"></div>
                <img v-else alt="" :src="product.remoteImagePath" class="img-fluid shadow-menu" style="border-radius: 5px"
                  onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';" />
              </td>
              <td style="padding-left: 10px">
                <div class="d-flex justify-content-between">
                  <div class="mt-3 h6 text-grey autoShowHide">
                    {{ product.name }}
                  </div>
                  <span>
                    <span hidden>
                      {{
                        (qshow = quantityShow(product.ProductSKU.productPLU.id))
                      }}
                    </span>
                    <span v-if="qshow > 0" class="mt-3 font-weight-bold badge badge-success">
                      {{ qshow }}
                    </span>
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <h6 class="text-black pb-3">
                    <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
                  </h6>
                  <small>{{ product.unit.name }}/{{ product.SKURatio }}</small>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div v-else-if="display === 'grid'" class="col-6" v-for="product in products" :key="product.objectId"
          @click="SKUObjectId = product.ProductSKU.objectId" data-toggle="modal" data-target="#detailModal">
          <div v-if="product.remoteImagePath === null ||
            product.remoteImagePath === undefined
            " class="square-box shadow-menu" :style="{
    'background-color': getImgColor(product.indexColor),
    width: '100%',
  }"></div>
          <img v-else alt="" :src="product.remoteImagePath" class="img-fluid shadow-menu" style="border-radius: 5px"
            onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';" />
          <div class="d-flex justify-content-between">
            <div class="mt-2 h6 text-grey autoShowHide">
              {{ product.name }}
            </div>
            <span>
              <span hidden>
                {{ (qshow = quantityShow(product.ProductSKU.productPLU.id)) }}
              </span>
              <span v-if="qshow > 0" class="mt-2 font-weight-bold badge badge-success">
                {{ qshow }}
              </span>
            </span>
          </div>
          <div class="d-flex justify-content-between">
            <h6 class="text-black pb-3">
              <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
            </h6>
            <small>{{ product.unit.name }}/{{ product.SKURatio }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal-p-bottom" id="detailModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <detail v-bind:cartTotal="cartTotal" v-bind:SKUObjectId="SKUObjectId" @update-cartTotal="updateCartTotal">
            </detail>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/pos'
import util from '@/util/util'
import '@/util/menu.css'
import Detail from './Detail'

export default {
  //props: ['cartTotal'],
  components: {
    Detail,
  },
  data() {
    return {
      products: [],
      categoryName: '',
      searchVal: '',
      display: 'grid',
      SKUObjectId: '',
      cartTotal: 0,
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    categoryObjectId() {
      return this.$route.params.categoryObjectId
    },
  },
  created() {
    this.getProduct()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
  },
  methods: {
    ...util,
    quantityShow(productPLUId) {
      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === productPLUId
      })

      let quantity = 0

      if (locationInCart !== -1) {
        quantity = this.cartLists[locationInCart].quantity
      }

      return quantity
    },
    updateCartTotal(total) {
      this.cartTotal = total

      this.$emit('update-cartTotal', total)

      console.log('grid: ' + this.cartTotal)
    },
    handleDisplay(opt) {
      this.display = opt
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goToDetail(objectId) {
      this.$router.push('/pickup/' + objectId + '/detail')
    },
    getProduct(page = 1) {
      const uid = this.uid
      let categoryObjectId = this.categoryObjectId
      let params = {}

      if (categoryObjectId === '') {
        params = {
          shopObjectId: this.shopLineObjectId,
          page: page,
          limit: 50,
          searchVal: this.searchVal,
        }
      } else {
        params = {
          shopObjectId: this.shopLineObjectId,
          categoryObjectId: categoryObjectId,
          searchVal: this.searchVal,
          page: page,
          limit: 50,
        }
      }

      poscrm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/Shop/getproduct/view',
        params: params,
      }).then((res) => {
        this.products = res.data.data
        /* พี่ถีต้องแก้ middleware กลับเป็น crm ของใหม่มันถูก replace เป็น requireToken */
        console.log(res.data)
        //console.log(this.products)
        /* this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage

        let pageCount = res.data.paginate.pageCount || 0

        if (pageCount > 1) {
          this.showPaginate = true
        } else {
          this.showPaginate = false
        } */
      })
    },
  },
  watch: {
    '$route.params.categoryObjectId'(val) {
      this.getProduct()
    },
    shopLineObjectId(newVal, oldVal) {
      this.getProduct()
    },
    searchVal(newVal, oldVal) {
      this.searchVal = newVal
      this.getProduct()
    },
  },
}
</script>
